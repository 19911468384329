type DataItem = {
  name: string;
  id: string | number;
};

export const makeEnum = (list: DataItem[]) => {
  return list.reduce((previousValue, currentValue) => {
    // @ts-ignore
    previousValue[parseInt(String(currentValue.id))] = currentValue.name;
    return previousValue;
  }, {});
};

const makeOptions = (list: DataItem[]) => {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

const makeGetName = (list: DataItem[]) => {
  const dataEnum = makeEnum(list);
  return function (id: string | number): string {
    return dataEnum[String(id)];
  };
};

export const paymentType = [
  {
    name: '寄付',
    id: 1,
  },
  {
    name: '到付',
    id: 2,
  },
];

export const paymentTypeEnum = makeEnum(paymentType);
export const getPaymentTypeName = makeGetName(paymentType);

export const productList = [
  {
    name: '万能支撑器',
    id: '1',
    type: 1, // 1 表示自研，2 表示调货
  },

  {
    name: '万能支撑器配件',
    id: '998',
    type: 1, // 1 表示自研，2 表示调货
  },

  {
    name: '缝隙式不锈钢盖板',
    id: '2',
    type: 2,
  },
  {
    name: '不锈钢收边条',
    id: '3',
    type: 2,
  },
  {
    name: '单开不锈钢井盖',
    id: '4',
    type: 2,
  },

  {
    name: '不锈钢井盖配件',
    id: '54',
    type: 2,
  },

  {
    name: '树脂排水沟',
    id: '5',
    type: 2,
  },
  {
    name: '树脂盖板',
    id: '6',
    type: 2,
  },
  {
    name: 'HDPE排水沟',
    id: '7',
    type: 2,
  },

  { name: 'HDPE排水沟盖板', id: '50', type: 2 },
  { name: 'HDPE排水沟配件', id: '55', type: 2 },

  //   { name: '不锈钢缝隙式盖板', id: '32', type: 2 },
  { name: '不锈钢方形篦子带外框', id: '34', type: 2 },
  { name: '不锈钢雨水篦子', id: '60', type: 2 },
  { name: '不锈钢鹅卵石U型槽', id: '36', type: 2 },
  { name: '阳台不锈钢水沟+盖板', id: '37', type: 2 },
  { name: '不锈钢缝隙式盖板（定制）', id: '38', type: 2, customized: true },
  { name: '不锈钢水篦子（定制）', id: '39', type: 2, customized: true },
  { name: '单开不锈钢井盖（定制）', id: '40', type: 2, customized: true },
  { name: '多开不锈钢井盖（定制）', id: '41', type: 2, customized: true },
  { name: '不锈钢收边条（定制）', id: '42', type: 2, customized: true },
  { name: '不锈钢树池篦子（定制）', id: '43', type: 2, customized: true },

  { name: '不锈钢异型定制件', id: '44', type: 2 },
  { name: '不锈钢定制件', id: '45', type: 2 },
  { name: '树脂草盆井', id: '48', type: 2 },
  { name: 'PE草盆井', id: '51', type: 2 },
  { name: '塑料草石隔离带', id: '52', type: 2 },
  {
    name: '厨房不锈钢盖板',
    id: '53',
    type: 2,
  },

  {
    name: '其他',
    id: '999',
    type: 2,
    customized: true,
  },
];

export const customizedProducts = productList
  .filter((item) => item.customized)
  .map((item) => item.id);

export const isTransferProduct = function (id: number | string): boolean {
  for (let item of productList) {
    if (String(item.id) === String(id)) {
      return item.type === 2;
    }
  }
  return false;
};

export const productEnum = makeEnum(productList);
export const getProductName = makeGetName(productList);

export const units = [
  {
    name: '套',
    id: '1',
  },
  {
    name: '个',
    id: '2',
  },
  {
    name: '米',
    id: '3',
  },
  {
    name: '块',
    id: '4',
  },
  {
    name: '条',
    id: '5',
  },
  {
    name: '片',
    id: '6',
  },
  {
    name: '件',
    id: '7',
  },

  {
    name: '元',
    id: '8',
  },
];

export const unitsOptions = makeOptions(units);

export const unitsEnum = makeEnum(units);
export const getUnitName = makeGetName(units);

export const taxList = [
  {
    name: '未税',
    id: '1',
    value: 1,
  },

  {
    name: '含税1%',
    id: '4',
    value: 0.99,
  },

  {
    name: '含税3%',
    id: '2',
    value: 0.97,
  },
  {
    name: '含税13%',
    id: '3',
    value: 0.87,
  },
];

export const taxOptions = makeOptions(taxList);

export const taxEnum = makeEnum(taxList);
export const getTaxName = makeGetName(taxList);

export const getTaxInfo = (id: string) => {
  for (let item of taxList) {
    if (item.id === id) {
      return item;
    }
  }
  return taxList[0];
};
export const orderTags = [
  {
    name: '已完成',
    id: '1',
  },
];

export const getTagName = makeGetName(orderTags);

export const OrderKey = `draft_order`;

type u8 = number;

export const UNINITIALIZED: u8 = 0;
export const INITIALIZED: u8 = 1;
export const RESOLVED: u8 = 2;
export const REJECTED: u8 = 3;

// 已核对，待核对，客户待确认
export const financialStatus = {
  0: '客户待确认',
  1: '待核对',
  2: '已核对',
};

export const pendingPaymentStatus = {
  1: '可处理',
  0: '暂不可处理',
};

const company_info = {
  name: '公司名称',
  value: '浙江创象新型材料有限公司',
};

export const payment_account_list = [
  {
    label: '浙江杭州余杭农村商业银行股份有限公司仓前支行',
    display: [
      company_info,
      {
        name: '开户银行',
        value: '浙江杭州余杭农村商业银行股份有限公司仓前支行',
      },
      {
        name: '账号',
        value: '201000198180094',
      },
      {
        name: '银联号',
        value: '402331003128',
      },
    ],
    value: 1,
  },

  {
    label: '中国工商银行股份有限公司杭州仓前支行',
    display: [
      company_info,
      {
        name: '开户银行',
        value: '中国工商银行股份有限公司杭州仓前支行',
      },
      {
        name: '账号',
        value: '1202201019000088461',
      },
      {
        name: '行号',
        value: '102331020103',
      },
    ],
    value: 10,
  },

  {
    label: '中国建设银行股份有限公司杭州城西科创支行',
    display: [
      company_info,
      {
        name: '开户银行',
        value: '中国建设银行股份有限公司杭州城西科创支行',
      },
      {
        name: '账号',
        value: '33050110425500000657',
      },
    ],
    value: 3,
  },

  // 公司名称：浙江创象新型材料有限公司<br/>开户银行：浙江泰隆商业银行股份有限公司杭州余杭支行
  //                   <br/>
  //                   账号：33020390201000013038<br/>
  //                   <br/>开户银行代码（313331080489)

  {
    label: '浙江泰隆商业银行股份有限公司杭州余杭支行',
    display: [
      company_info,
      {
        name: '开户银行',
        value: '浙江泰隆商业银行股份有限公司杭州余杭支行',
      },
      {
        name: '账号',
        value: '33020390201000013038',
      },
      {
        name: '开户银行代码',
        value: '313331080489',
      },
    ],
    value: 4,
  },

  //   公司名称：浙江创象新型材料有限公司
  // 开户银行:杭州银行股份有限公司保俶支行
  // 一般户账号:3301041060002271025
  // 杭州银行行号：313331000186

  {
    label: '杭州银行股份有限公司保俶支行',
    display: [
      company_info,
      {
        name: '开户银行',
        value: '杭州银行股份有限公司保俶支行',
      },
      {
        name: '一般户账号',
        value: '3301041060002271025',
      },
      {
        name: '杭州银行行号',
        value: '313331000186',
      },
    ],
    value: 7,
  },

  // 账户名称：安徽创象新材料有限公司
  // 开户银行：中国银行股份有限公司蒙城宝塔支行
  // 账户号码：179769343722

  {
    label: '中国银行股份有限公司蒙城宝塔支行',
    display: [
      {
        name: '公司名称',
        value: '安徽创象新材料有限公司',
      },
      {
        name: '开户银行',
        value: '中国银行股份有限公司蒙城宝塔支行',
      },
      {
        name: '账户号码',
        value: '179769343722',
      },
    ],
    value: 8,
  },

  // Beneficiary’s Name:Zhejiang Trumshi Building Material Co., LTD.

  // BENEFICLARY Account NO.:  3301 0410 6000 2272 577  (USD Account)

  // BENEFICLARY BANK:  Bank of Hangzhou Co.,Ltd.

  // SWIFT CODE: HZCBCN2HXXX

  // BANK ADD:  No. 46, Qingchun Road, Hangzhou, P.R.China

  // INTERMEDIARY  BANK：
  // (转汇行)

  // CITIBANK N.A. NEW YORK, NY U.S.A

  // SWIFT：CITIUS33XXX
  {
    label: 'BENEFICLARY BANK: Bank of Hangzhou Co.,Ltd.',
    value: 9,
    display: [
      {
        name: 'Beneficiary’s Name',
        value: 'Zhejiang Trumshi Building Material Co., LTD',
      },
      {
        name: 'BENEFICLARY Account NO',
        value: '3301 0410 6000 2272 577  (USD Account)',
      },
      {
        name: 'BENEFICLARY BANK',
        value: 'Bank of Hangzhou Co.,Ltd',
      },

      {
        name: 'SWIFT CODE',
        value: 'HZCBCN2HXXX',
      },

      {
        name: 'BANK ADD',
        value: 'No. 46, Qingchun Road, Hangzhou, P.R.China',
      },
      {
        name: 'INTERMEDIARY  BANK',
        value: 'CITIBANK N.A. NEW YORK, NY U.S.A',
      },

      {
        name: 'SWIFT',
        value: 'CITIUS33XXX',
      },
    ],
  },

  {
    label: 'BENEFICLARY BANK: CHINA CONSTRUCTION BANK HANGZHOU CHENGXI KECHUANG SUB BRANCH',
    value: 5,
    display: [
      {
        name: 'Beneficiary’s Name',
        value: 'Zhejiang Trumshi Building Material Co., LTD',
      },

      {
        name: 'BENEFICLARY Account NO.',
        value: '3305 0110 4255 0000 0838  (USD Account)',
      },

      {
        name: 'SWIFT CODE',
        value: 'PCBCCNBJZJX',
      },

      {
        name: 'BANK ADD',
        value: 'RM103 5#BUILDING NO.998 WEST WENYI RD YUHANG HANGZHOU CHINA',
      },
    ],
  },
  {
    label: 'BENEFICLARY BANK:   ZHEJIANG TAILONG COMMERCIAL BANK CO.,LTD',
    value: 6,

    display: [
      {
        name: 'Beneficiary’s Name',
        value: 'Zhejiang Trumshi Building Material Co., LTD',
      },

      {
        name: 'BENEFICLARY Account NO.',
        value: '33020390201000013046 (USD Account)',
      },

      {
        name: 'SWIFT CODE',
        value: 'ZJTLCNBHXXX',
      },

      {
        name: 'BANK ADD',
        value: 'NO.188 NANGUAN ROAD TAIZHOU ZHEJIANG CHINA',
      },
    ],
  },
  {
    label: '中国农业银行',
    value: 2,
    private: true,
    display: [
      {
        name: '开户账户',
        value: '6228 4803 2915 9498 875',
      },

      {
        name: '开户银行',
        value: '中国农业银行杭州西兴支行',
      },

      {
        name: '户主',
        value: '姜茜茜',
      },
    ],
  },
];

export function getBankInfo(value: number) {
  for (let item of payment_account_list) {
    if (item.value === value) {
      return {
        name: item.label,
        display: item.display,
        detail: item.display
          .map((item) => {
            return `${item.name}: ${item.value}`;
          })
          .join('</br>'),
      };
    }
  }
}
export const payment_accounts = payment_account_list.map((item) => {
  return {
    label: item.label + `(${item.private ? '对私银行' : '对公银行'})`,
    value: item.value,
  };
});

export function get_payment(value: number): string {
  for (let item of payment_accounts) {
    if (value === item.value) {
      return item.label;
    }
  }
  return '';
}
